import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: ()=> import('@/views/Home')
  },
  {
    path: '/login',
    name: 'Login',
	component: ()=> import('@/views/Login')
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
   // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})
//挂载路由导航守卫
router.beforeEach((to,from,next)=>{
  //to 将要访问的路径

  //from 从哪个路径来

  //next 放行 next()直接放行 next('/xxxxx')强制跳转到某页米啊
  if(to.path === '/login') return next();
   const tokenStr = window.localStorage.getItem('token')
  //const tokenStr = window.sessionStorage.getItem('token')
  if(!tokenStr) return next('/login')
  next()
})
export default router
