import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Elementui from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import http from './http'
import CryptoJS from 'crypto-js'
import '@/assets/dialogDraggable.js'
//const YMDA = 'Ac@fdg45^8D*%JK)'
function aesDecrypt(data, mm) {
	    var mm = CryptoJS['enc']['Utf8']['parse'](mm);
	    var dataa = CryptoJS['AES']['decrypt'](data, mm, {
	        'mode': CryptoJS['mode']['ECB'],
	        'padding': CryptoJS['pad']['Pkcs7']
	    });
	    return CryptoJS['enc']['Utf8']['stringify'](dataa)['toString']();
	}
Vue.prototype.cl = 'Ac@fdg45^8D*%JK)'

Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.prototype.ffym = aesDecrypt
Vue.prototype.$axios = axios
Vue.use(Elementui)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
