<template>
	<router-view></router-view>
</template>
<script>
	
	export default {
		
		data(){
			return {
				isRouterAlive:true
			}
		},
		methods:{
			
		}
	}
</script>
<style>
	html,
	body {
		margin: 0;
		padding: 0;
		height: 100%;
	}
	
</style>
