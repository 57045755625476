import axios from 'axios';

let instance = axios.create({
	//baseURL:'http://124.223.184.37:5555',
	//baseURL:'http://43.143.53.85:5888',
	baseURL:'http://124.223.101.130:5000',
	timeout:50000
})
//请求拦截
instance.interceptors.request.use(config=>{
	//请求拦截要处理的事情
	//config.headers.authorization = 'Bearer '+window.sessionStorage.getItem('token')
	config.headers.authorization = 'Bearer '+window.localStorage.getItem('token')
	return config
},err=>{
	console.error('请求失败',err)
	//window.localStorage.clear()
})

//响应拦截
instance.interceptors.response.use(res=>{
	//响应相关的处理
	return res
},err=>{
	console.error('响应失败',err)
	//window.localStorage.clear()
})


//封装http请求
/**
 *封装http请求的方法
 *@params {*} option 配置对象 .属性包括:
 *method 请求方式
 *path 请求接口地址
 *params 请求参数
 */

async function http(option = {}){
	let result = null;
	if(option.method === 'get' || option.method === 'delete'){
		await instance[option.method](
		option.path,
		{
			params:option.params,
		}
	).then(res=>{
		result = res;
	}).catch(err=>{
		result = err
	})
	}else if(option.method === 'post' || option.method === 'put'){
		await instance[option.method](
			option.path,
			option.params
		).then(res=>{
			result = res
		}).catch(err=>{
			result = err
		})
	}
	
	return result
}

export default http